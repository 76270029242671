import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [3, 4, 5],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">Hey 👋, I'm Joey!</Text>
    <p>{`I am a Javascript Engineer from Nairobi, Kenya 🇰🇪. I mostly build React apps powered by GraphQL backends.`}</p>
    <p>{`I learn by sharing my knowledge with the community through blog posts, workshops, and Open-source. I also hold `}<a parentName="p" {...{
        "href": "https://www.meetup.com/Nairobi-GraphQL-Meetup/"
      }}>{`Graphql meetups`}</a>{` so be sure to check that out if that's your jam.`}</p>
    <p>{`I take contract/project-based jobs from time to time. If you want to discuss a project, you can drop me an email at joeynimu`}{`[at]`}{`gmail.com.`}</p>
    <p>{`During my spare time, I spend time with my family, watching Liverpool FC play and making road trips to discover new places.
You can reach me on Twitter `}<a parentName="p" {...{
        "href": "https://twitter.com/africansinatra"
      }}>{`@africansinatra`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      